
import { defineComponent } from "vue";
import { useField } from "vee-validate";
import * as yup from "yup";
import CodeHighlighter2 from "@/components/highlighters/CodeHighlighter2.vue";
import {
  example2Html,
  example2Js
} from "@/views/resources/documentation/forms/vee-validate/codes.ts";

export default defineComponent({
  name: "example-2",
  components: {
    CodeHighlighter2
  },
  setup() {
    const { errorMessage, value } = useField(
      "fieldName",
      yup
        .string()
        .required()
        .min(8)
    );

    return {
      value,
      errorMessage,
      example2Html,
      example2Js
    };
  }
});
